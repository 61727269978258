import './../css/credits.css';
import axios from 'axios';
import React from 'react';
import { BlocksRenderer, type BlocksContent } from '@strapi/blocks-react-renderer';

interface Props {
    locale: string,
    setPageLoaded: Function
}

interface State {
    content: any,
}

class Credits extends React.Component<Props, State> {
 
    state: State = {
        content: null,
    }

    componentDidMount() {
        this.getContent(this.props.locale);
    }

    getContent = (locale: string) => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/credits?populate=*&locale=' + locale)
            .then((response: any) => { 
                this.setState({
                    content: response.data.data.attributes
                }, () => { this.props.setPageLoaded("credits") });
            })
            .catch((error: any) => 
            {
                if(locale !== 'en' && error.response && error.response.status === 404)
                {
                    console.log('Error: Could not find localized content > loading English');
                    this.getContent('en');
                }
                else
                {
                    console.log(error.message);
                }
            });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.locale !== this.props.locale)
        {
            this.getContent(this.props.locale);
        }
    }

    render() {
        return (
            <div id="credits" className="page black"> 
                {this.state.content && 
                    <div>
                        <h1>{this.state.content.title}</h1>
                        <div id="credits-text">
                            <BlocksRenderer content={this.state.content.credits} />
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default Credits;