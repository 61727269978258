import './../css/watch.css';
import axios from 'axios';
import React from 'react';
import { BlocksRenderer, type BlocksContent } from '@strapi/blocks-react-renderer';

interface Props {
    locale: string,
    setPageLoaded: Function
}

interface State {
    content: any,
}

class Watch extends React.Component<Props, State> {
 
    state: State = {
        content: null,
    }

    componentDidMount() {
        this.getContent(this.props.locale);
    }

    getContent = (locale: string) => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/watch?populate=*&locale=' + locale)
            .then((response: any) => { 
                this.setState({
                    content: response.data.data.attributes
                }, () => { this.props.setPageLoaded("watch") });
            })
            .catch((error: any) => 
            {
                if(locale !== 'en' && error.response && error.response.status === 404)
                {
                    console.log('Error: Could not find localized content > loading English');
                    this.getContent('en');
                }
                else
                {
                    console.log(error.message);
                }
            });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.locale !== this.props.locale)
        {
            this.getContent(this.props.locale);
        }
    }

    render() {
        return (
            <div id="watch" className="page black"> 
                {this.state.content && 
                    <div>
                        <h1>{this.state.content.title}</h1>
                        <div id="watch-flex">
                            <div id="watch-vod">
                                <h2>{this.state.content.vodTitle}</h2>
                                {this.state.content.vodButtonLink &&
                                    <>
                                        <a href={this.state.content.vodButtonLink} target='_blank' rel='noreferrer'>
                                            <img src={process.env.REACT_APP_STRAPI_URL + this.state.content.poster.data.attributes.formats.small.url} alt="" />
                                        </a>
                                        <a className="button black" href={this.state.content.vodButtonLink} target='_blank' rel='noreferrer'>{this.state.content.vodButtonLabel}</a>
                                    </>
                                }
                                {!this.state.content.vodButtonLink &&
                                    <img src={process.env.REACT_APP_STRAPI_URL + this.state.content.poster.data.attributes.formats.small.url} alt="" />
                                }
                            </div>
                            <div id="watch-screenings">
                                <h2>{this.state.content.screeningsTitle}</h2>
                                {this.state.content.screenings.map((screening:any, index:number) =>
                                    <a key={'screening-' + index} href={screening.link} target='_blank' rel='noreferrer'>
                                        {screening.date} - {screening.venue}
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default Watch;