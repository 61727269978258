import React from 'react'
import './css/style.css';
import Menu from './components/menu';
import Home from './components/home';
import Synopsis from './components/synopsis';
import Gallery from './components/gallery';
import Credits from './components/credits';
import Contact from './components/contact';
import Poster from './components/poster';
import { HelmetProvider } from 'react-helmet-async';
import Watch from './components/watch';

interface Props {
    
}

interface State {
    currentPage: string,
    scrolling: boolean,
    allPagesLoaded: boolean,
    locale: string,
    pagesLoaded: Map<string, boolean>,
    scrollTimeout?: NodeJS.Timeout
}

class App extends React.Component<Props, State> {

    state: State = {
        currentPage: "",
        scrolling: false,
        allPagesLoaded: false,
        locale: 'en',
        pagesLoaded: new Map<string, boolean>([
            ["home", false],
            ["synopsis", false],
            ["gallery", false],
            ["watch", false],
            ["poster", false],
            ["credits", false],
            ["contact", false]
        ]),
        scrollTimeout: undefined
    }

    componentDidMount() {
        // Set scroll event listener
        window.addEventListener('scroll', this.updatePageFromScroll);
    }

    updatePageFromScroll = () => {

        if(!this.state || this.state.scrolling) return;

        //console.log('scrolling: ' + window.scrollY);
        const elements = document.getElementsByClassName("page");
        if(elements)
        {
            Array.from(elements).forEach(element => {
                const elementY = element.getBoundingClientRect().top + window.scrollY - 48;
                if(window.scrollY > elementY - 200 && window.scrollY < elementY + 200)
                {
                    var page = element.id;
                    if(page === "home") page = "";
                    this.setState({currentPage: page});

                    var path = window.location.pathname.replace("/","");
                    if(path !== page)
                    {
                        // locale
                        var locale = "/" + this.state.locale;
                        if(locale === "/en") locale = "";

                        //console.log("Setting push: " + locale + "/" + page);
                        window.history.pushState({}, "", locale + "/" + page);
                    }
                }
            });
        }
        
    }

    setCurrentPage = (page: string) => {

        // locale
        var locale = "/" + this.state.locale;
        if(locale === "/en") locale = "";

        // Presskit
        if(page === "presskit") page = "contact";

        // Set url
        var history = page;
        if (!history) history = "";
        window.history.pushState({}, "", locale + "/" + history); // Set url in browser

        // Clear the previous timeout if it is still running
        clearTimeout(this.state.scrollTimeout); 
        
        // Set State (pass the timeout to disable 'is scrolling' to scrollTimeout so we can clear it)
        this.setState({currentPage: page, scrolling: true, scrollTimeout: setTimeout(() => this.setState({scrolling: false}), 800)}, () =>{
            this.scrollToPage(page, 'smooth', true);
        });
    }

    scrollToPage = (page: string, behavior: string, offset: boolean) => {
        // Scroll to the page
        var y = 0;
        const element = document.getElementById(page);
        if (element) y = element.getBoundingClientRect().top + window.scrollY;
        if (offset) y -= 48;
        window.scroll({
            top: y,
            behavior: behavior as ScrollBehavior
        });
    }

    setPageLoaded = (page: string) =>
    {
        if(this.state.allPagesLoaded) return;

        // Set page to loaded
        this.state.pagesLoaded.set(page, true);

        // Check if all pages are loaded
        let allLoaded = true;
        this.state.pagesLoaded.forEach((value: boolean, key: string) => {
            if(!value) allLoaded = false;
            //console.log("loaded: " + key + " > " + value);
        });

        // Once all pages are loaded
        if(allLoaded)
        {
            //console.log('Pages loaded: Scrolling to page from url');

            var params = window.location.pathname.slice(1).split("/"); // Remove leading slash and split on subsequent slashes
            var locale = "en";
            var newPage = "";

            if(params[0] === "en" || params[0] === "fr" || params[0] === "ar" )
            {
                locale = params[0];
                newPage = params[1];
            }
            else
            {
                newPage = params[0];
            }

            var pageFromUrl = newPage;
            if(pageFromUrl === "presskit") pageFromUrl = "contact";

            this.setState({
                allPagesLoaded: true,
                locale: locale,
                currentPage: newPage, 
                scrolling: false
            }, () => {
                // Update html class
                document.getElementsByTagName('html')[0].setAttribute('class', locale);
                this.scrollToPage(pageFromUrl, 'auto', true);
            });
            
        }
    }

    setLocale = (locale: string) =>
    {
        this.setState({
            locale: locale
        }, () => {
            // update page
            this.setCurrentPage(this.state.currentPage);
            // Update html class
            document.getElementsByTagName('html')[0].setAttribute('class', locale);
        });
    }

    render()
    {
        return (
            <div className={`app ${this.state.locale}`}>
                <HelmetProvider>
                    <Menu currentPage={this.state.currentPage} setCurrentPage={this.setCurrentPage} setLocale={this.setLocale} locale={this.state.locale} />
                    <Home setPageLoaded={this.setPageLoaded} locale={this.state.locale} />
                    <Synopsis setPageLoaded={this.setPageLoaded} locale={this.state.locale} />
                    <Gallery setPageLoaded={this.setPageLoaded} locale={this.state.locale} />
                    <Watch setPageLoaded={this.setPageLoaded} locale={this.state.locale} />
                    <Poster setPageLoaded={this.setPageLoaded} locale={this.state.locale} />
                    <Credits setPageLoaded={this.setPageLoaded} locale={this.state.locale} />
                    <Contact setPageLoaded={this.setPageLoaded} locale={this.state.locale} />
                </HelmetProvider>
            </div>
        )
    }
}

export default App;
