import './../css/poster.css';
import axios from 'axios';
import React, { Fragment } from 'react';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { BlocksRenderer, type BlocksContent } from '@strapi/blocks-react-renderer';
import TarotCards1 from '../img/tarot-cards-01.png';
import TarotCards2 from '../img/tarot-cards-02.png';

interface Props {
    locale: string,
    setPageLoaded: Function
}

interface State {
    content: any,
}

class Poster extends React.Component<Props, State> {
 
    state: State = {
        content: null,
    }

    componentDidMount() {
        this.getContent(this.props.locale);
        
        Fancybox.bind("[data-fancybox]", {
            Thumbs: false
        })

        return () => {
            Fancybox.destroy();
        };
    }

    getContent = (locale: string) => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/poster?populate=*&locale=' + locale)
            .then((response: any) => { 
                this.setState({
                    content: response.data.data.attributes
                }, () => { this.props.setPageLoaded("poster") });
                console.log(response.data.data.attributes);
            })
            .catch((error: any) => 
            {
                if(locale !== 'en' && error.response && error.response.status === 404)
                {
                    console.log('Error: Could not find localized content > loading English');
                    this.getContent('en');
                }
                else
                {
                    console.log(error.message);
                }
            });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.locale !== this.props.locale)
        {
            this.getContent(this.props.locale);
        }
    }

    render() {
        return (
            <Fragment>
                <div id="poster" className="page white"> 
                    {this.state.content && 
                        <div className='poster-content'>
                            <h1>{this.state.content.title}</h1>
                            <div className='poster-poster'>
                                <a href={process.env.REACT_APP_STRAPI_URL + this.state.content.poster.data.attributes.formats.large.url} data-fancybox="poster"><img src={process.env.REACT_APP_STRAPI_URL + this.state.content.poster.data.attributes.formats.small.url} alt="" /></a>
                            </div>
                            <div className='poster-text'>
                                <BlocksRenderer content={this.state.content.text} />
                            </div>
                        </div>
                    }
                </div>
                <div className='poster-cards'>
                    <img src={TarotCards1} />
                    <img src={TarotCards2} />
                </div>
            </Fragment>
        )
    }
}

export default Poster;