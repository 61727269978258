import './../css/menu.css';
import axios from 'axios';
import React from 'react';

interface Props {
    locale: string,
    currentPage: string,
    setCurrentPage: Function,
    setLocale: Function
}

interface State {
    menu: any,
    locales: any,
    hamburger: boolean
}

class Menu extends React.Component<Props, State> {
 
    state: State = {
        menu: null,
        locales: null,
        hamburger: false
    }

    componentDidMount() {
        this.getContent(this.props.locale);

        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/i18n/locales')
            .then(({ data }) => {
                this.setState({
                    locales: data
                });
            })
            .catch((error) => console.log(error))
    }

    getContent = (locale: string) => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/menus?filters[name][$eq]=main&populate=*&locale=' + locale)
            .then((response: any) => {
                this.setState({
                    menu: response.data.data[0].attributes
                });
            })
            .catch((error: any) => 
            {
                if(locale !== 'en' && error.response && error.response.status === 404)
                {
                    console.log('Error: Could not find localized content > loading English');
                    this.getContent('en');
                }
                else
                {
                    console.log(error.message);
                }
            });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.locale !== this.props.locale)
        {
            this.getContent(this.props.locale);
        }
    }

    clickMenuItem = (page: string) => 
    {
        this.props.setCurrentPage(page);
        this.setState({hamburger: false});
    }

    render()
    {
        return (
            <div id="menu" className={this.state.hamburger ? 'open' : ''}> 
                {this.state.menu && 
                    <div id="main">
                        <div id="main-hamburger">
                            <button onClick={() => this.setState({hamburger: !this.state.hamburger})}>{this.state.menu.title}</button>
                        </div>
                        {this.state.menu.menuItems.map((menuItem: any) => 
                            <button key={menuItem.id} 
                                className={this.props.currentPage === menuItem.slug || (!this.props.currentPage && !menuItem.slug) ? "active" : ""} 
                                onClick={() => this.clickMenuItem(menuItem.slug)}>
                                    {menuItem.label}
                            </button>
                        )}
                    </div>
                }
                {this.state.locales && this.state.locales.length > 1 &&
                    <div id="lang">
                        {this.state.locales.map((lang: any) => 
                            <span key={lang.id}>
                                <button key={lang.id} 
                                    className={this.props.locale === lang.code ? "active" : ""} 
                                    onClick={() => this.props.setLocale(lang.code)}>
                                        {lang.code}
                                </button>
                            </span>
                        )}
                    </div>
                }
            </div>
        );
    }
}

export default Menu;