import './../css/synopsis.css';
import axios from 'axios';
import React from 'react';
import ReactMarkdown from 'react-markdown';

interface Props {
    locale: string,
    setPageLoaded: Function
}

interface State {
    content: any
}

class Synopsis extends React.Component<Props, State> {
    
    state: State = {
        content: null
    }

    componentDidMount() {
        this.getContent(this.props.locale);
    }

    getContent = (locale: string) => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/synopsis?populate=*&locale=' + locale)
            .then((response: any) => { 
                this.setState({
                    content: response.data.data.attributes
                }, () => { this.props.setPageLoaded("synopsis")
            });
            })
            .catch((error: any) => 
            {
                if(locale !== 'en' && error.response && error.response.status === 404)
                {
                    console.log('Error: Could not find localized content > loading English');
                    this.getContent('en');
                }
                else
                {
                    console.log(error.message);
                }
            });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.locale !== this.props.locale)
        {
            this.getContent(this.props.locale);
        }
    }

    render() {        
        return (
            <div id="synopsis" className="page white"> 
                <div className="content">
                    {this.state.content && 
                        <div>
                            <h1>
                                {this.state.content.title}
                            </h1>
                            <div id="synopsis-text">
                                {this.state.content.text.map((block: any, blockIndex: any) => 
                                    <div key={'block-' + blockIndex}>
                                        {block.type === 'heading' &&
                                            <h5>
                                                {block.children.map((child: any, childIndex: any) => 
                                                    <span key={'block-' + blockIndex + '-' +childIndex} className={child.italic ? 'italic' : ' ' && child.bold ? 'bold' : ''}>{child.text}</span>
                                                )}
                                            </h5>
                                        }
                                        {block.type === 'paragraph' &&
                                            <p>
                                                {block.children.map((child: any, childIndex: any) => 
                                                    <span key={'block-' + blockIndex + '-' +childIndex} className={child.italic ? 'italic' : ' ' && child.bold ? 'bold' : ''}>{child.text}</span>
                                                )}
                                            </p>
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Synopsis;