import './../css/home.css';
import axios from 'axios';
import React from 'react';
import titleImage from './../img/paradigma-title.gif';
import logosImage from './../img/sponsors.png';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

interface Props {
    locale: string,
    setPageLoaded: Function
}

interface State {
    content: any,
    backgroundStyle: any
}

class Home extends React.Component<Props, State> {
 
    state: State = {
        content: null,
        backgroundStyle: null
    }

    componentDidMount() {
        this.getContent(this.props.locale);

        Fancybox.bind("[data-fancybox]", {
            Thumbs: false
        })

        return () => {
            Fancybox.destroy();
        };
    }

    getContent = (locale: string) => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/home?populate=*&locale=' + locale)
            .then((response: any) => { 
                this.setState({
                    content: response.data.data.attributes
                }, () => { this.props.setPageLoaded("home") });
            })
            .catch((error: any) => 
            {
                if(locale !== 'en' && error.response && error.response.status === 404)
                {
                    console.log('Error: Could not find localized content > loading English');
                    this.getContent('en');
                }
                else
                {
                    console.log(error.message);
                }
            });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.locale !== this.props.locale)
        {
            this.getContent(this.props.locale);
        }
    }

    render() {
        return (

            <div id="home" className="page black"> 
                <div className="content">
                    <div><img id="logo" alt="Paradigma: A film by Jozef Devillé & Pablo Eekman" src={titleImage} /></div>
                    {this.state.content &&
                        <a href={this.state.content.trailerUrl} data-fancybox="trailer" className='trailerButton'><i className="material-icons">play_arrow</i> {this.state.content.trailerButtonLabel}</a>
                    }
                    <div><img id="logos" alt="Sponsors" src={logosImage} /></div>
                </div>
            </div>
        )
    }
}

export default Home;