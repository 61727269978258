import './../css/gallery.css';
import axios from 'axios';
import React from 'react';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
var _ = require('lodash');

interface Props {
    locale: string,
    setPageLoaded: Function
}

interface State {
    content: any
    active: string
}

class Gallery extends React.Component<Props, State> {
 
    state: State = {
        content: null,
        active: 'stills'
    }

    componentDidMount() {
        this.getContent(this.props.locale);
        
        Fancybox.bind("[data-fancybox]", {
            Thumbs: false
        })

        return () => {
            Fancybox.destroy();
        };
    }

    getContent = (locale: string) => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/stills?populate=*&locale=' + locale)
            .then((response: any) => { 
                this.setState({
                    content: _.shuffle(response.data.data.attributes.stills.data)
                }, () => { this.onImagesLoaded(); });
            })
            .catch((error: any) => 
            {
                if(locale !== 'en' && error.response && error.response.status === 404)
                {
                    console.log('Error: Could not find localized content > loading English');
                    this.getContent('en');
                }
                else
                {
                    console.log(error.message);
                }
            });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.locale !== this.props.locale)
        {
            this.getContent(this.props.locale);
        }
    }

    switchGallery = (gallery: string) => {
        this.setState({
            active: gallery
        });
    };

    onImagesLoaded = () => {
        this.props.setPageLoaded("gallery");
    }

    render() {
        return (
            <div id="stills" className="page black"> 
                {this.state.content && 
                    <div className="content">
                            <div className={`gallery-flex`}>
                                {this.state.content.map((still: any) => 
                                    <div key={still.id}>
                                        <a data-fancybox="stills" href={process.env.REACT_APP_STRAPI_URL + (still.attributes.formats.large ? still.attributes.formats.large.url : still.attributes.url)}>
                                            <img src={process.env.REACT_APP_STRAPI_URL + still.attributes.formats.small.url} alt="" />
                                        </a>
                                    </div>
                                )}
                            </div>
                    </div>
                }
            </div>
        )
    }
}

export default Gallery;